import loadable from '@loadable/component';

import { Drawer } from '#components/ui/drawer/drawer';

import { useDrawerAction, useDrawerState } from './drawer.context';

const CartSidebarView = loadable(
  () => import('#components/cart/cart-sidebar-view'),
  {
    resolveComponent: (component) => component.CartSidebarView,
    ssr: false,
  }
);
const MobileUserMenu = loadable(
  () => import('#components/layouts/mobile-menu/mobile-user-menu')
);
const MobileMainMenu = loadable(
  () => import('#components/layouts/mobile-menu/mobile-main-menu')
);
const MobileCategoryMenu = loadable(
  () => import('#components/layouts/mobile-menu/mobile-category-menu'),
  {
    resolveComponent: (component) => component.MobileCategoryMenu,
    ssr: false,
  }
);

export const ManagedDrawer = () => {
  const { isOpen, view } = useDrawerState();
  const { closeDrawer } = useDrawerAction();

  return (
    <Drawer
      open={isOpen}
      onClose={() => closeDrawer()}
      variant={
        view === 'FILTER_VIEW' || view === 'MAIN_MENU'
          ? /* || view === ('FILTER_LAYOUT_TWO_VIEW' as '')*/
            'left'
          : 'right'
      }
    >
      {view === 'CART_SIDEBAR' && <CartSidebarView />}
      {view === 'FILTER_VIEW' && <MobileCategoryMenu />}
      {view === 'MAIN_MENU' && <MobileMainMenu />}
      {view === 'USER_MENU' && <MobileUserMenu />}
    </Drawer>
  );
};
