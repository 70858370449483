import type { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { ApolloProvider } from '@apollo/client';
import type { i18n } from 'i18next';
import { HelmetProvider } from 'react-helmet-async';
import { I18nextProvider } from 'react-i18next';

import { DrawerProvider } from '#components/ui/drawer/drawer.context';
import { ModalProvider } from '#components/ui/modal/modal.context';
import { CartProvider } from '#store/cart.context';

interface AppProviderProps {
  apolloClient: ApolloClient<NormalizedCacheObject>;
  children: React.ReactNode;
  helmetContext?: object;
  i18n: i18n;
  pageProps?: any;
  urlPathname?: string;
}
const AppProviders: React.FC<AppProviderProps> = ({
  apolloClient,
  children,
  helmetContext,
  i18n,
}: AppProviderProps) => {
  return (
    <HelmetProvider context={helmetContext}>
      <I18nextProvider i18n={i18n}>
        <ApolloProvider client={apolloClient}>
          <CartProvider>
            <DrawerProvider>
              <ModalProvider>{children}</ModalProvider>
            </DrawerProvider>
          </CartProvider>
        </ApolloProvider>
      </I18nextProvider>
    </HelmetProvider>
  );
};

export default AppProviders;
