import loadable from '@loadable/component';

import { Modal } from '#components/ui/modal/modal';

import { useModalAction, useModalState } from './modal.context';

//const OtpLoginView = loadable(() => import('#components/auth/otp-login-view'));
const Login = loadable(() => import('#framework/auth/login'));
const Register = loadable(() => import('#framework/auth/register'));
const ForgotPassword = loadable(
  () => import('#framework/auth/forgot-password')
);
const ProductDetailsModalView = loadable(
  () => import('#framework/products/product'),
  {
    ssr: false,
  }
);
//const ShopInfoCard = loadable(() => import('@components/shops/sidebar'));
const CreateOrUpdateAddressForm = loadable(
  () => import('#framework/address/create-or-update')
);
const AddressDeleteView = loadable(
  () => import('#framework/address/delete-view')
);
/*const AddOrUpdateCheckoutContact = loadable(
  () => import('#components/checkout/contact/add-or-update')
);*/
//const ProfileAddOrUpdateContact = loadable(
//  () => import('@framework/profile/profile-add-or-update-contact')
//);

const ManagedModal = () => {
  const { isOpen, view, data } = useModalState();
  const { closeModal } = useModalAction();

  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
      fullWidth={view === 'PRODUCT_DETAILS'}
    >
      {view === 'LOGIN_VIEW' && <Login />}
      {view === 'REGISTER' && <Register />}
      {view === 'FORGOT_VIEW' && <ForgotPassword />}
      {/*view === 'OTP_LOGIN' && <OtpLoginView />*/}
      {view === 'ADD_OR_UPDATE_ADDRESS' && <CreateOrUpdateAddressForm />}
      {/*view === 'ADD_OR_UPDATE_CHECKOUT_CONTACT' && (
        <AddOrUpdateCheckoutContact />
      )*/}
      {/*view === 'ADD_OR_UPDATE_PROFILE_CONTACT' && (
        <ProfileAddOrUpdateContact />
      )*/}
      {view === 'DELETE_ADDRESS' && <AddressDeleteView />}
      {view === 'PRODUCT_DETAILS' && (
        <ProductDetailsModalView productId={data} />
      )}
      {/*view === 'SHOP_INFO' && (
        <ShopInfoCard
          data={data}
          cardClassName='!hidden'
          className='!flex flex-col !w-screen !h-screen max-w-screen-sm'
        />
      )*/}
    </Modal>
  );
};

export default ManagedModal;
