export const fadeInRight = (duration = 0.3) => ({
  from: {
    right: '-100%',
    transition: {
      type: 'easeInOut',
      duration: duration,
    },
  },
  to: {
    right: 0,
    transition: {
      type: 'easeInOut',
      duration: duration,
    },
  },
});
