import loadable from '@loadable/component';
import type { i18n as i18nType } from 'i18next';
import type { FC, ReactNode } from 'react';
import { StrictMode } from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client';
import { navigate } from 'vite-plugin-ssr/client/router';

import { SiteLayout } from '#components/layouts/site-layout';
import { ManagedDrawer } from '#components/ui/drawer/managed-drawer';
import ManagedModal from '#components/ui/modal/managed-modal';
import AppProviders from '#framework/app/providers';
import { initializeApollo } from '#framework/utils/apollo';
import { initializeWorkbox } from '#framework/utils/workbox';
import { createI18n } from '#lib/i18n.client';
import { PageContextProvider } from '#store/page.context';
import type { RootPageContext } from '#types/page';

import '#assets/css/main.css';

const CookieBanner = loadable(
  () =>
    import('#components/cookie-banner/cookie-banner').catch(() => ({
      default: () => <></>,
    })),
  { fallback: <></> }
);

const StoreMessageBanner = loadable(
  () =>
    import('#components/store-message-banner/store-message-banner').catch(
      () => ({
        default: () => <></>,
      })
    ),
  { fallback: <></> }
);

const forceClientRender = false;

const FallbackLayout: FC<{ children: ReactNode }> = ({ children }) => (
  <SiteLayout>{children}</SiteLayout>
);

let container: HTMLElement;
let i18n: i18nType;
let root: ReturnType<typeof createRoot> | ReturnType<typeof hydrateRoot>;

export const render = async (pageContext: RootPageContext) => {
  if (forceClientRender) {
    console.warn(
      'Forcing client-side rendering. This is only intended for development.'
    );
  }

  const { apolloCache, isHydration, Page, i18nCache } = pageContext;

  if (Page.redirect) {
    const redirectTo = Page.redirect(pageContext);
    if (redirectTo) {
      await navigate(redirectTo, { overwriteLastHistoryEntry: true });
      return;
    }
  }

  // Prepare all the things.
  const apolloClient = initializeApollo(
    !forceClientRender ? apolloCache : undefined
  );
  initializeWorkbox();
  container ??= document.getElementById('root')!;
  i18n ??= await createI18n();

  (window as any).__APOLLO_CLIENT__ ??= apolloClient;

  if (isHydration && !forceClientRender) {
    i18n.store.data = i18nCache as typeof i18n.store.data;
  }

  // Create the app.
  const Layout = (Page.Layout ??= FallbackLayout);
  const page = (
    <StrictMode>
      <PageContextProvider value={pageContext}>
        <AppProviders
          i18n={i18n}
          //Router={BrowserRouter}
          apolloClient={apolloClient}
          urlPathname={pageContext.urlParsed.pathname}
        >
          <Layout>
            <Page {...pageContext} />
            <ManagedDrawer />
            <ManagedModal />
            <StoreMessageBanner />
            <CookieBanner />
          </Layout>
        </AppProviders>
      </PageContextProvider>
    </StrictMode>
  );

  // Render the app.
  if (isHydration && !forceClientRender) {
    root = hydrateRoot(container, page);
  } else {
    if (!root) {
      root = createRoot(container);
    }
    root.render(page);
  }
};

export const clientRouting = true;
export const hydrationCanBeAborted = true;
